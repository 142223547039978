export default {
    computed: {
        headers() {
            return [
                {
                    value: 'id',
                    text: this.$t('labels.id'),
                    type: 'string',
                },
                {
                    value: 'name',
                    text: this.$t('labels.name'),
                    type: 'string',
                },
                {
                    value: 'administratorName',
                    text: this.$t('labels.contact_name'),
                    type: 'string',
                },
                {
                    value: 'administratorPhone',
                    text: this.$t('labels.contact_phone'),
                    type: 'string',
                },
            ]
        },
    },
}

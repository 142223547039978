<template lang="pug">
    div
        page-title {{ $t('companies.list_page_title') }}
        companies-table(:selected-filters.sync="selectedFilters" :selected-items.sync="selectedItems")
            template(v-slot:actions)
                create-button(:route-name="routeType.COMPANY_CREATE" tabbed)
        portal(to='footer')
            export.mr-2(v-if="isSuperAdmin" :selected-filters="selectedFilters" :selected-items="selectedItems")

</template>

<script>
import PageTitle from '@/components/layout/components/pageTitle'
import CompaniesTable from '@/components/pages/companies/Table'
import CreateButton from '@/components/form/Actions/CreateButton'
import routeType from '@/router/routeType'
import Export from '@/components/pages/companies/Export'
import AuthMixin from '@/components/mixins/AuthMixin'

export default {
    components: {
        PageTitle,
        CompaniesTable,
        CreateButton,
        Export,
    },
    mixins: [AuthMixin],
    data() {
        return {
            routeType,
            selectedFilters: {},
            selectedItems: [],
        }
    },
}
</script>

<template lang="pug">
    v-btn(
        :loading="busy"
        color="primary"
        @click='exportCompany')
        v-icon(left) cloud_download
        | {{ $t('actions.export') }}

</template>
<script>
import ExportCompanies from '@/services/Companies/ExportCompanies'

export default {
    props: {
        selectedFilters: Object,
        selectedItems: Array,
    },
    data() {
        return {
            busy: false,
        }
    },
    methods: {
        exportCompany() {
            this.busy = true
            ExportCompanies({
                ...{ id: this.selectedItems },
                ...this.selectedFilters,
            }).finally(() => {
                this.busy = false
            })
        },
    },
}
</script>

export default {
    computed: {
        listFilters() {
            return [
                {
                    key: 'cloudcanteens.cutoffTime',
                    type: 'after',
                    inputType: 'checkbox',
                    label: 'labels.cloud_canteen_for_future',
                    value: this.$date().endOf('day').format(),
                },
            ]
        },
    },
}
